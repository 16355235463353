import Cookies from 'js-cookie';
import moment from 'moment';
import { TRANSLATE } from 'src/submodules/commons-misc/constant';
import { createTimeZoneList } from 'src/submodules/ui-components/tz';
import { getStoredSettings } from 'src/utils/settings';

export function saveObjToLocal(title, value) {
	localStorage.setItem('_' + title, JSON.stringify(value));
}

export function removeSingleItemFromLocal(title) {
	localStorage.removeItem('_' + title);
}

export function getObjFromLocal(objTitle) {
	let obj = null;

	try {
		const storedData = localStorage.getItem('_' + objTitle);

		if (storedData) {
			obj = JSON.parse(storedData);
		}
	} catch (err) {
		// If stored data is not a strigified JSON this might fail,
		// that's why we catch the error
	}

	return obj;
}

export function cleanLocalStorage() {
	for (var k in localStorage) {
		if (k.startsWith('_')) {
			localStorage.removeItem(k);
		}
	}
}

export function isEmptyObject(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(Object.keys(obj).length === 0);
}

export function isEmptyList(obj) {
	if (obj === undefined || obj === null) return true;
	else return Boolean(obj.length === 0);
}

export function isEmptyString(string) {
	return Boolean(string === undefined || string === null || string === '');
}

export function getValueAssetState(list, key) {
	var obj = list?.filter(as => as?.key === key);
	if (obj && obj?.length > 0) {
		return obj[0]?.value;
	}
}

export function getDomainName() {
	let hostName = window.location.hostname;
	return hostName.substring(
		hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1)
	);
}

export function diffTime(end, start) {
	let endMoment = moment(end);
	let startMoment = moment(start);
	const diff_s = endMoment.diff(startMoment, 'milliseconds');
	return moment
		.utc(moment.duration(diff_s, 'milliseconds').asMilliseconds())
		.format('DD HH:mm:ss');
}

export function getHoursFromSeconds(secs) {
	if (secs) {
		return moment.utc(secs * 1000).format('HH:mm:ss');
	} else {
		return '-';
	}
}

export function changeMomentLang(settings) {
	let language = settings?.language;
	var current = language.split('-')[0];
	moment.locale(current, {
		longDateFormat: {
			LT: 'HH:mm:ss',
			L: 'MM/DD/YYYY',
			l: 'M/D/YYYY',
			LL: 'MMMM Do YYYY',
			ll: 'MMM D YYYY',
			LLL: 'MMMM Do YYYY LT',
			lll: 'MMM D YYYY LT',
			LLLL: 'dddd, MMMM Do YYYY LT',
			llll: 'ddd, MMM D YYYY LT'
		}
	});
}

export function saveOffset(timezone) {
	const value = timezone?.value;
	Cookies.set('offset', value, { domain: getDomainName() });
}

export function saveTimeZoneList(list) {
	const value = JSON.stringify(list);
	sessionStorage.setItem('timeZoneList', value);
	localStorage.setItem('timeZoneList', value);
	Cookies.set('timeZoneList', value, { domain: getDomainName() });
}

export function getOffset() {
	let offset = Cookies.get('offset');
	if (!offset) {
		const list = createTimeZoneList();
		var timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
		var timesSplit = timezoneString.split('/');
		var timezoneByBrowser = list.filter(t => t.label.includes(timesSplit[1]));
		offset = timezoneByBrowser[0]?.value;
	}
	Cookies.set('offset', offset, { domain: getDomainName() });
	return parseInt(offset);
}

export function getTimeZoneList() {
	let timeZoneList = localStorage.getItem('timeZoneList');
	if (!timeZoneList) {
		timeZoneList = createTimeZoneList();
		return timeZoneList;
	} else {
		return JSON.parse(timeZoneList);
	}
}

export function calculateOffset(timeZone) {
	const ct = require('countries-and-timezones');
	const timezone = ct.getTimezone(timeZone);
	var result = timezone.utcOffset / 60;
	if (isCestOrCet(timezone?.name)) {
		result++;
	}
	return result;
}

function isCestOrCet(timezoneName) {
	const timeZoneName = Intl.DateTimeFormat(moment.locale, {
		timeZoneName: 'short',
		timeZone: timezoneName
	})
		.formatToParts()
		.find(i => i.type === 'timeZoneName').value;

	if (!timeZoneName.includes('GMT') && timeZoneName.includes('CEST')) {
		return true;
	}
	return false;
}

export function viewNumeric(value) {
	let settings = getStoredSettings();
	let language = JSON.parse(settings)?.language;
	if (value) {
		if (!isNaN(value)) {
			return new Intl.NumberFormat(language, { style: 'decimal', 
				minimumFractionDigits: 0, 
				maximumFractionDigits: 1  }).format(
				value
			);
		} else {
			return value;
		}
	} else {
		return '';
	}
}

export function formatOdometer() {
	let settings = getStoredSettings();
	let language = JSON.parse(settings)?.language;
	if (language.indexOf('it') == 0) {
		return '(.ddd),ddd';
	}
}

export function viewMomentData(value) {
	if (value) {
		if (getDiffDays(value)) {
			return moment(value)
				.utcOffset(getOffset())
				.calendar();
		} else {
			return moment(value)
				.utcOffset(getOffset())
				.format('DD/MM/YYYY HH:mm:ss');
		}
	}
}

export function getDiffDays(endDate) {
	var startDate = new Date().getTime();
	var dateStart = moment(startDate)
		.utcOffset(getOffset())
		.format('YYYY-MM-DD');
	var dateEnd = moment(endDate)
		.utcOffset(getOffset())
		.format('YYYY-MM-DD');
	var calendarOk = false;
	calendarOk = dateStart === dateEnd;
	if (!calendarOk) {
		var newStartDate = new Date();
		newStartDate = moment(newStartDate).add(-1, 'day');
		dateStart = moment(newStartDate.valueOf())
			.utcOffset(getOffset())
			.format('YYYY-MM-DD');
		calendarOk = dateStart === dateEnd;
	}
	return calendarOk;
}

export function getCalendarStringWithoutTime(value) {
	var startDate = new Date().getTime();
	var dateStart = moment(startDate).format('YYYY-MM-DD');
	var dateEnd = moment(value).format('YYYY-MM-DD');
	if (dateStart === dateEnd) {
		return TRANSLATE.TODAY_DATE;
	} else {
		return TRANSLATE.YESTERDAY_DATE;
	}
}

export function viewMomentDataONLY(value) {
	if (value) {
		if (!getDiffDays(value)) {
			return moment(value)
				.utcOffset(getOffset())
				.format('DD/MM/YYYY');
		}
	}
}

export function viewMomentTIME(value) {
	const now = moment();
	if (value) {
		return moment(value)
			.utcOffset(getOffset())
			.format('HH:mm:ss');
	}
}

export function getColumUppercase(columns) {
	let col = columns.map(c => {
		c.label = c.label.toUpperCase();
		return c;
	});
	return col;
}

export function hasWhiteSpace(s) {
	return /\s/g.test(s);
}

export function hideColumn(col, arraycols, hideDefault = false) {
	let hide = false;
	if (arraycols.length > 0) {
		if (arraycols.includes(col)) {
			hide = true;
		}
	} else {
		if (hideDefault) {
			hide = true;
		}
	}
	return hide;
}

export const handleSave = (nameCols, arraycols) => {
	localStorage.setItem(nameCols, JSON.stringify(arraycols));
};

export function getCOLS(nameCols) {
	if (localStorage.getItem(nameCols) !== undefined) {
		return JSON.parse(localStorage.getItem(nameCols));
	}
}

export function handleHideCol(
	colKey,
	arraycols,
	setArrCols,
	nameCols,
	setLoading,
	columns,
	setErrorLimit
) {
	setLoading(true);
	setErrorLimit(false);
	const size = arraycols.length;
	const columnSize = columns.length;
	const columnHidden = columns.filter(col => col.hide);
	const limitMax = columnSize - columnHidden.length >= 10;
	let hideColumns = [];
	if (size > 0) {
		hideColumns = arraycols;
		const index = hideColumns.indexOf(colKey);
		if (index === -1) {
			hideColumns.push(colKey);
		} else {
			if (limitMax) {
				setLoading(false);
				setErrorLimit(true);
				return;
			}
			hideColumns.splice(index, 1);
		}
	} else {
		hideColumns.push(colKey);
	}
	setArrCols(hideColumns);
	setTimeout(() => {
		setLoading(false);
		handleSave(nameCols, hideColumns);
	}, 200);
}
